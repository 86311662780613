import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import toastr from "toastr";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCH4Jnn1bGpvC2ZYlh_m4H9g26HSCcCO4I",
  authDomain: "handelsonwheels.firebaseapp.com",
  projectId: "handelsonwheels",
  storageBucket: "handelsonwheels.appspot.com",
  messagingSenderId: "755769736147",
  appId: "1:755769736147:web:97f5823e2012bc572e8d26",
  measurementId: "G-YS9T8KPNB3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

async function getFirestoreToEmail() {
  const url = "https://4rlj13.buildship.run/firestoretoemail";

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "swing",
  showMethod: "slideDown",
  hideMethod: "slideUp",
};

async function fetchAndIncrementCount() {
  const counterRef = doc(db, "counters", "inquiryCounter");

  try {
    const counterDoc = await getDoc(counterRef);

    if (counterDoc.exists()) {
      const currentCount = counterDoc.data().count;

      // Increment the counter atomically
      await updateDoc(counterRef, {
        count: currentCount + 1,
      });

      return currentCount + 1;
    } else {
      console.error("Counter document does not exist.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching and updating the counter:", error);
    return null;
  }
}

async function addInquiry(docData) {
  try {
    const newCount = await fetchAndIncrementCount();

    if (newCount !== null) {
      await setDoc(doc(db, "eventInquiries", newCount.toString()), docData);
      console.log("Document written with ID:", newCount);
      toastr.success("We got your inquiry!", "Success!");
      getFirestoreToEmail();
    } else {
      throw new Error("Failed to generate a new inquiry ID.");
    }
  } catch (e) {
    console.error("Error adding document:", e);
    toastr.error(
      "Error adding document. Please contact lisa@handelssgv.com",
      "Uh oh!",
      { timeOut: "10000" }
    );
  }
}

const bookUs = document.querySelectorAll(".book-us-btn");
const webInquiry = document.querySelector(".web-inquiry-blur");
const close = document.querySelector("#close");
const inquiryContent = document.querySelector(".web-inquiry");

document
  .getElementById("inquiry-form")
  .addEventListener("submit", async (e) => {
    e.preventDefault();
    webInquiry.classList.remove("web-inquiry-open");

    const counterRef = doc(db, "counters", "inquiryCounter");

    let currentCount = 0; // Default value

    try {
      const counterDoc = await getDoc(counterRef);

      if (counterDoc.exists()) {
        currentCount = counterDoc.data().count;
      } else {
        console.error("Counter document does not exist!");
        return;
      }
    } catch (error) {
      console.error("Error fetching count:", error);
      return;
    }

    console.log(currentCount);

    const formData = new FormData(e.target);
    const siteAddress = `${formData.get("street-address")}, ${formData.get(
      "city"
    )}, ${formData.get("state")} ${formData.get("postal-code")}`;
    const docData = {
      id: currentCount + 1,
      dateCreated: Date(),
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      phoneNumber: formData.get("phoneNumber"),
      company: formData.get("company"),
      eventName: formData.get("eventName"),
      eventDate: formData.get("eventDate"),
      siteAddress: siteAddress,
      startTime: formData.get("startTime"),
      endTime: formData.get("endTime"),
      plannedGuestCount: formData.get("plannedGuestCount"),
      operation: formData.get("operation"),
      package: formData.get("packageChoice"),
      notes: formData.get("notes"),
      reference: formData.get("reference"),
    };

    await addInquiry(docData);
    e.target.reset();
  });

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("inquiry-form");
  const submitButton = document.getElementById("submit-btn");

  form.addEventListener("input", function () {
    submitButton.disabled = !form.checkValidity();
  });

  form.addEventListener("submit", function (e) {
    if (!form.checkValidity()) {
      e.preventDefault();
    }
  });

  submitButton.addEventListener("pointerdown", function (e) {
    if (submitButton.disabled) {
      toastr.error(
        "Please fill out all required fields before submitting.",
        "Wait"
      );
    }
    e.preventDefault();
  });
});

document.getElementById("operation").addEventListener("change", function () {
  console.log("change");
  const selectedValue = this.value;
  const packageOption = document.getElementById("packageOption");

  packageOption.innerHTML = "";

  if (selectedValue === "Handel's on Wheels Truck") {
    packageOption.innerHTML = `
      <label for="packageChoice">Package Choice:</label>
      <select id="packageChoice" name="packageChoice">
        <option value="" disabled selected>Choose a package</option>
        <option value="Mini">Mini ($6.00 per guest) *Reserved for large parties and schools</option>
        <option value="Basic">Basic ($7.00 per guest)</option>
        <option value="Premium">Premium ($9.00 per guest)</option>
        <option value="Custom">Custom (Let us know what you're thinking below!)</option>
      </select>
    `;
  }
});

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

/**
 * Book Us Button (Web Inquiry)
 */

bookUs.forEach((button) => {
  button.addEventListener("click", (e) => {
    webInquiry.classList.add("web-inquiry-open");
    e.stopPropagation(); // Prevent the click from propagating to the document
  });
});

close.addEventListener("click", () => {
  webInquiry.classList.remove("web-inquiry-open");
});

document.addEventListener("click", (e) => {
  if (!inquiryContent.contains(e.target) && !bookUs[0].contains(e.target)) {
    webInquiry.classList.remove("web-inquiry-open");
  }
});

/**
 * Scroll Trigger animations
 */

const packageCard = gsap.utils.toArray(".package-card");
packageCard.forEach((card) => {
  gsap.from(".package-card", {
    opacity: 0,
    x: 400,
  });

  gsap.to(".package-card", {
    scrollTrigger: {
      trigger: ".package-card",
      toggleActions: "restart none none none",
      start: "top bottom",
      end: "bottom top",
    },
    opacity: 1,
    x: 0,
    stagger: {
      each: 0.1,
    },
    duration: 1,
  });
});

gsap.to(".owners", {
  scrollTrigger: {
    trigger: ".owners",
    scrub: 2,
    start: "top bottom",
    end: "bottom center",
  },
  y: -200,
  zIndex: 10,
});

gsap.from(".why-handels-wrapper", {
  y: 200,
});

gsap.to(".why-handels-wrapper", {
  scrollTrigger: {
    trigger: ".why-handels",
    scrub: 2,
    start: "top bottom",
    end: "bottom center",
  },
  y: -125,
});

gsap.from(".why-text", {
  opacity: 0,
  x: 200,
});

gsap.to(".why-text", {
  scrollTrigger: {
    trigger: ".why-handels",
    toggleActions: "restart none none none",
    start: "top 70%",
    end: "bottom bottom",
  },
  opacity: 1,
  x: 0,
  duration: 1,
});

// Navbar animations
{
  window.addEventListener("scroll", function () {
    var navbar = document.getElementById("navbar");
    if (!navbar.classList.contains("sticky")) {
      navbar.classList.toggle("sticky", window.scrollY > 800);
    } else if (
      navbar.classList.contains("sticky") &&
      this.window.scrollY < 300
    ) {
      navbar.classList.toggle("sticky");
      navbar.classList.toggle("sticky-gone");
    }

    if (navbar.classList.contains("sticky-gone") && this.window.scrollY < 150) {
      navbar.classList.toggle("sticky-gone");
    }
  });
}

// navbar toggler
{
  const toggleButton = document.getElementsByClassName("nav-toggle-button")[0];
  const navbarLinks = document.getElementsByClassName("nav-links")[0];
  const navbar = document.getElementsByClassName("navbar")[0];

  toggleButton.addEventListener("click", () => {
    navbarLinks.classList.toggle("active");
    navbar.classList.toggle("nav-active");
  });
}

/**
 * Magnetic Truck
 */
const cursor = document.querySelector(".cursor");
const truck = document.querySelector(".truck-lights");
const area = document.querySelector(".area");

const lerp = (x, y, a) => x * (1 - a) + y * a;

area.addEventListener("mousemove", (dets) => {
  const drim = area.getBoundingClientRect();
  const xStart = drim.x;
  const xEnd = drim.x + drim.width;
  const leftRight = gsap.utils.mapRange(xStart, xEnd, 0, 1, dets.clientX);
  const yStart = drim.y;
  const yEnd = drim.y + drim.height;
  const upDown = gsap.utils.mapRange(yStart, yEnd, 0, 1, dets.clientY);
  document.addEventListener("DOMContentLoaded", function () {
    gsap.to(cursor, {
      scale: 1,
    });
  });
  gsap.to(".truck", {
    x: lerp(-25, 25, leftRight),
    y: lerp(-25, 25, upDown),
  });
  gsap.to(".truck-lights", {
    x: lerp(-25, 25, leftRight),
    y: lerp(-25, 25, upDown),
  });
  gsap.to(".blob", {
    x: lerp(-15, 15, leftRight),
    y: lerp(-15, 15, upDown),
  });
});

area.addEventListener("mouseleave", (dets) => {
  document.addEventListener("DOMContentLoaded", function () {
    gsap.to(cursor, {
      scale: 0,
    });
  });
  gsap.to(".truck", {
    ease: "elastic.out(1, 0.2)",
    duration: 2,
    x: 0,
    y: 0,
  });
  gsap.to(".truck-lights", {
    ease: "elastic.out(1, 0.2)",
    duration: 2,
    x: 0,
    y: 0,
  });
  gsap.to(".blob", {
    ease: "elastic.out(1, 0.2)",
    duration: 2,
    x: 0,
    y: 0,
  });
});

truck.addEventListener("click", () => {
  const lights = document.querySelector(".truck-lights");
  lights.style.classList.toggle("active");
});

let truckLights = document.getElementsByClassName("truck-lights")[0];

area.addEventListener("click", () => {
  truckLights.classList.toggle("active");
});
// END Magnetic truck ------------------------------------

const scrollers = document.querySelectorAll(".scroller");

// If a user hasn't opted in for recuded motion, then we add the animation
if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
  addAnimation();
}

addAnimation();

function addAnimation() {
  scrollers.forEach((scroller) => {
    // add data-animated="true" to every `.scroller` on the page
    scroller.setAttribute("data-animated", true);

    // Make an array from the elements within `.scroller-inner`
    const scrollerInner = scroller.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);

    // For each item in the array, clone it
    // add aria-hidden to it
    // add it into the `.scroller-inner`
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem);
    });

    // Hover effect
  });
}
